<ng-container *appIfIsDesktop>
  <input
    [ngbTypeahead]="inputHover ? searchCity : null"
    [placement]="'bottom-left'"
    [popupClass]="'search-location-popup search-location-popup-desktop search-location-popup-desktop-city'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    [resultTemplate]="rt"
    type="text"
    class="form-control form-control-input input-placeholder input-placeholder-hidden"
    id="search-input"
    placeholder="        City or Address"
    (selectItem)="selectItem($event)"
    (input)="onInputClick($event)"
    (focus)="onFocusInput()"
    (mouseenter)="onHoverInput()"
  >

  <ng-template #rt let-r="result" let-t="term">
    <div
      class="d-flex align-items-center"
      [class.disabled]="r.disabled"
      [ngClass]="{'find-me-item': r.description === 'Find me'}"
    >
      <i *ngIf="r.icon" [class]="r.icon"></i>

      <ngb-highlight [result]="r.description" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
</ng-container>

<input
  *appIfIsMobile
  [ngbTypeahead]="searchCityMobile"
  [placement]="'bottom-left'"
  [popupClass]="'search-location-popup search-location-popup-mobile'"
  [focusFirst]="false"
  [editable]="false"
  [inputFormatter]="googleMapsService.formatter"
  [resultFormatter]="googleMapsService.formatter"
  [ngClass]="inputClass"
  type="text"
  class="form-control"
  placeholder="       City or Address"
  autocomplete="new-search-city"
  (selectItem)="selectItemMobile($event)"
>
