import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from '../../../../decorators/auth.decorator';
import { CurrentUser } from '../../../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../../../decorators/is-logged-in.decorator';
import { Subscribe } from '../../../../decorators/subscribe.decorator';
import { RestaurantExplore } from '../../../../domains/restaurant-explore';
import { User } from '../../../../domains/user';
import { Picker } from '../../../../models/picker';
import { SearchParams } from '../../../../models/search-params';
import { SearchResult } from '../../../../models/search-result';
import { CheckoutService } from '../../../../services/checkout.service';
import { NavigatorService } from '../../../../services/navigator.service';
import { RestaurantService } from '../../../../services/restaurant.service';

@Component({
  selector: 'app-navbar-bottom-icon-recent',
  templateUrl: './navbar-bottom-icon-recent.component.html',
  styles: [':host { display: contents; }']
})
export class NavbarBottomIconRecentComponent implements OnInit {
  @Input() isRestaurant = false;
  @Input() searchResult: SearchResult;
  @Input() explore = false;
  @Input() searchParams: SearchParams;

  @Output() restaurantClicked = new EventEmitter<RestaurantExplore>();

  @IsLoggedIn() isLoggedIn: boolean;

  @CurrentUser() currentUser: User;

  isSubscribed = false;
  picker: Picker;

  constructor(
    private checkoutService: CheckoutService,
    private activatedRoute: ActivatedRoute,
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit(): void {
    if (this.currentUser) {
      this.checkoutService.isUserSubscribed(this.currentUser).subscribe(value => {
        this.isSubscribed = value;

        this.activatedRoute.params.subscribe(() => {
          if (this.isLoggedIn && this.isSubscribed) {
            this.restaurantService.getRecentPicker().then(picker => {
              this.picker = picker;
            });
          }
        });
      });
    }
  }

  onRestaurantsPickerSelected(hostname: string) {
    const restaurant =
      this.searchResult.restaurants.find(restaurantI => restaurantI.hostname === hostname);
    this.restaurantClicked.emit(restaurant);
  }

  onRecentRestaurantChanged(selectedValue: string) {
    this.navigatorService.goToRestaurant(selectedValue);
  }

  @Auth()
  @Subscribe()
  onMIconClicked() {
  }

  @Auth()
  @Subscribe()
  openMembershipModal() {
  }
}
