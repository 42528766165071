<ng-container *appIfIsMobile>
  <div *ngIf="position.isMobile" class="search-filters-mobile text-white">
    <form [formGroup]="searchFormGroup.searchForm">
      <div class="row font-size-0-96rem mx-1-25rem">
        <div class="col-6 d-flex flex-column-reverse">
          <input ngbAutofocus type="hidden"/>

          <label class="search-filters-mobile-label mb-0 d-block position-relative">
            <select
              required
              aria-label="State"
              class="custom-select custom-select-mobile d-block hide-arrow text-white"
              formControlName="stateId"
              (change)="onStateChange($event.target)"
            >
              <option value="null" hidden [selected]="!stateSelected">State</option>

              <ng-container *ngIf="countrySelected">
                <option *ngFor="let option of stateSelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </label>

          <label class="search-filters-mobile-label mb-0 d-block position-relative">
            <select
              #countySelectRef
              required
              aria-label="County"
              class="custom-select custom-select-mobile text-white d-block w-100 hide-arrow"
              formControlName="countyId"
              (change)="onCountyChange($event.target)"
            >
              <option value="null" hidden [selected]="!countySelected">County</option>

              <ng-container *ngIf="stateSelected">
                <ng-container *ngIf="countySelect.isGrouped">
                  <ng-container *ngFor="let option of countySelect.options;">
                    <option *ngIf="countySelectRef.value == option.value" [value]="option.value">
                      {{ option.label }}
                    </option>
                  </ng-container>

                  <optgroup [label]="nearByLabel">
                    <ng-container *ngFor="let option of countySelect.options;">
                      <option
                        *ngIf="option.group == nearByLabel && countySelectRef.value != option.value"
                        [value]="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </ng-container>
                  </optgroup>

                  <optgroup [label]="otherLabel">
                    <ng-container *ngFor="let option of countySelect.options">
                      <option *ngIf="option.group == otherLabel" [value]="option.value">
                        {{ option.label }}
                      </option>
                    </ng-container>
                  </optgroup>
                </ng-container>

                <ng-container *ngIf="!countySelect.isGrouped">
                  <option *ngFor="let option of countySelect.options" [value]="option.value">
                    {{ option.label }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </label>

          <label
            *ngIf="countySelected?.hasRegions"
            class="search-filters-mobile-label mb-0 d-block position-relative"
          >
            <select
              #regionSelectRef
              required
              aria-label="Region"
              class="custom-select custom-select-mobile d-block w-100 hide-arrow text-white"
              formControlName="regionId"
              (change)="onRegionChange($event.target)"
            >
              <option value="null" hidden [selected]="!regionSelected">Region</option>

              <ng-container *ngIf="countySelected">
                <option *ngFor="let option of regionSelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </label>

          <label
            *ngIf="!countySelected?.hasRegions && countySelected || countySelected?.hasRegions && regionSelected"
            class="search-filters-mobile-label mb-0 position-relative"
            [ngClass]="{'d-none h-0 pointer-events-none': !isCityShown(), 'd-block': !isCityShown()}"
          >
            <select
              #citySelectRef
              required
              aria-label="City"
              class="custom-select custom-select-mobile d-block w-100 hide-arrow text-white"
              formControlName="cityId"
              (change)="onCityChange($event.target)"
            >
              <option value="null" hidden [selected]="!citySelected">City</option>

              <ng-container *ngIf="countySelected">
                <option *ngFor="let option of citySelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </label>

          <label
            *ngIf="citySelected?.bigCity"
            class="search-filters-mobile-label mb-0 d-block position-relative"
          >
            <select
              #districtSelectRef
              required
              aria-label="Neighborhood"
              class="custom-select custom-select-mobile d-block w-100 hide-arrow text-white"
              formControlName="districtId"
              (change)="onDistrictChange($event.target)"
            >
              <option value="null" hidden [selected]="!districtSelected">Neighborhood</option>

              <ng-container *ngIf="citySelected">
                <option *ngFor="let option of districtSelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </label>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="position.asTitle" class="search-filters-mobile">
    <form [formGroup]="searchFormGroup.searchForm">
      <input ngbAutofocus type="hidden"/>

      <label
        *ngIf="!countySelected?.hasRegions && countySelected || countySelected?.hasRegions && regionSelected"
        class="search-filters-mobile-label mb-0 d-block position-relative"
      >
        <ng-container *ngIf="!citySelected?.bigCity">
          <ng-container *ngIf="citySelect.hasSingleOption()">
            <span class="custom-select-navbar-title">
              {{ citySelect.options[0].label }}
            </span>
          </ng-container>

          <ng-container *ngIf="citySelect.hasMultipleOptions()">
            <select
              #titleCitySelectRef
              [appSelectWidthByText]="true"
              required
              aria-label="City"
              class="custom-select-navbar-title custom-select custom-select-mobile d-block w-100 hide-arrow"
              formControlName="cityId"
              (change)="onCityChange($event.target)"
            >
              <ng-container *ngIf="countySelected">
                <option *ngFor="let option of citySelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="citySelected?.bigCity">
          <ng-container *ngIf="districtSelect.hasSingleOption()">
            <span class="custom-select-navbar-title">
              {{ districtSelect.options[0].label }}
            </span>
          </ng-container>

          <ng-container *ngIf="districtSelect.hasMultipleOptions()">
            <select
              #titleDistrictSelectRef
              [appSelectWidthByText]="true"
              required
              aria-label="City"
              class="custom-select-navbar-title custom-select custom-select-mobile d-block w-100 hide-arrow"
              formControlName="districtId"
              (change)="onDistrictChange($event.target)"
            >
              <ng-container *ngIf="citySelected">
                <option *ngFor="let option of districtSelect.options" [value]="option.value">
                  {{ option.label }}
                </option>
              </ng-container>
            </select>
          </ng-container>
        </ng-container>
      </label>
    </form>
  </div>
</ng-container>

<div *appIfIsDesktop class="search-filters-desktop">
  <form *ngIf="position.isUnderLogo && countrySelected" [formGroup]="searchFormGroup.searchForm">
    <div class="search-filters-desktop-under-logo row text-black">
      <label class="mb-0 text-left mr-3">
        <ng-template
          [ngTemplateOutlet]="ngSelectState"
          [ngTemplateOutletContext]="{classes: 'ng-select-explore-search-title-under-logo dark'}"
        ></ng-template>
      </label>

      <div (mouseleave)="countyHover = false">
        <label
          [ngClass]="{'visibility-hidden': stateSelected == null}"
          class="mb-0 text-left mr-3"
          (mouseover)="countyHover = true"
        >
          <ng-template
            [ngTemplateOutlet]="ngSelectCounty"
            [ngTemplateOutletContext]="{classes: 'ng-select-explore-search-title-under-logo dark'}"
          ></ng-template>
        </label>

        <div
          [ngClass]="{'visibility-hidden': !countyHover || !countySelected}"
          class="flex-content-vc-hc cursor-pointer"
          (click)="openMapWithCounty()"
        ></div>
      </div>

      <div *ngIf="countySelected?.hasRegions" class="d-flex" (mouseleave)="regionHover = false">
        <label class="mb-0 text-left mr-3" (mouseover)="regionHover = true">
          <ng-template
            [ngTemplateOutlet]="ngSelectRegion"
            [ngTemplateOutletContext]="{classes: 'ng-select-explore-search-title-under-logo dark'}"
          ></ng-template>
        </label>

        <div
          [ngClass]="{'visibility-hidden': !regionHover || !regionSelected}"
          class="flex-content-vc-hc cursor-pointer"
          (click)="openMapWithRegion()"
        ></div>
      </div>

      <div
        *ngIf="!countySelected?.hasRegions && countySelected || countySelected?.hasRegions && regionSelected"
        [ngClass]="{'d-none': !isCityShown(), 'd-flex': isCityShown()}"
        (mouseleave)="cityHover = false"
      >
        <label
          [ngClass]="{'visibility-hidden': stateSelected == null}"
          class="mb-0 text-left mr-3"
          (mouseover)="cityHover = true"
        >
          <ng-template
            [ngTemplateOutlet]="ngSelectCity"
            [ngTemplateOutletContext]="{classes: 'ng-select-explore-search-title-under-logo dark'}"
          ></ng-template>
        </label>

        <div
          [ngClass]="{'visibility-hidden': !cityHover || !isLocalitySelected }"
          class="flex-content-vc-hc cursor-pointer"
          (click)="openMapWithCity()"
        ></div>
      </div>

      <div
        *ngIf="citySelected?.bigCity"
        class="d-flex"
        (mouseleave)="cityHover = false"
      >
        <label
          [ngClass]="{'visibility-hidden': stateSelected == null}"
          class="mb-0 text-left mr-3"
          (mouseover)="cityHover = true"
        >
          <ng-template
            [ngTemplateOutlet]="ngSelectDistrict"
            [ngTemplateOutletContext]="{classes: 'ng-select-explore-search-title-under-logo dark'}"
          ></ng-template>
        </label>

        <div
          [ngClass]="{'visibility-hidden': !cityHover || !isLocalitySelected }"
          class="flex-content-vc-hc cursor-pointer"
          (click)="openMapWithCity()"
        ></div>
      </div>
    </div>

    <ng-template #ngSelectState let-classes="classes">
      <ng-select
        appNgSelectOnHover
        [items]="stateSelect.options"
        [searchable]="false"
        [ngClass]="classes"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="State"
        formControlName="stateId"
        (change)="onStateChange($event)"
      ></ng-select>
    </ng-template>

    <ng-template #ngSelectCounty let-classes="classes">
      <ng-select
        #ngSelectCountyComponent
        appNgSelectOnHover
        appModalCloseOnClickPrevent
        [items]="countySelect.options"
        [searchable]="false"
        [ngClass]="classes"
        [hideSelected]="true"
        groupBy="group"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="County"
        formControlName="countyId"
        (change)="onCountyChange($event)"
      ></ng-select>
    </ng-template>

    <ng-template #ngSelectRegion let-classes="classes">
      <ng-select
        #ngSelectRegionComponent
        appNgSelectOnHover
        [items]="regionSelect.options"
        [searchable]="false"
        [ngClass]="classes"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="Region"
        formControlName="regionId"
        (change)="onRegionChange($event)"
      ></ng-select>
    </ng-template>

    <ng-template #ngSelectCity let-classes="classes">
      <ng-select
        #ngSelectCityComponent
        appNgSelectOnHover
        [items]="citySelect.options"
        [searchable]="false"
        [ngClass]="classes"
        groupBy="group"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow marked"
        bindLabel="label"
        bindValue="value"
        placeholder="City"
        formControlName="cityId"
        (change)="onCityChange($event)"
      ></ng-select>
    </ng-template>

    <ng-template #ngSelectDistrict let-classes="classes">
      <ng-select
        #ngSelectDistrictComponent
        appNgSelectOnHover
        [items]="districtSelect.options"
        [searchable]="false"
        [ngClass]="classes"
        groupBy="group"
        class="ng-select-explore ng-select-dropdown auto-grow hide-arrow"
        bindLabel="label"
        bindValue="value"
        placeholder="Neighborhood"
        formControlName="districtId"
        (change)="onDistrictChange($event)"
      ></ng-select>
    </ng-template>
  </form>
</div>
