import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { DeviceType } from '../../../../models/enums/device-type';
import { Place } from '../../../../models/place';
import { SearchParams } from '../../../../models/search-params';

@Component({
  selector: 'app-search-location-modal',
  templateUrl: './search-location-modal.component.html',
  styleUrls: ['./search-location-modal.component.scss']
})
export class SearchLocationModalComponent implements OnInit {
  @Input() searchParams: SearchParams;

  @Output() selectPlace = new EventEmitter<Place>();
  @Output() findMe = new EventEmitter<boolean>();
  @Output() selectRestaurant = new EventEmitter<boolean>();

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  hiddenRestPlaceholder = false;
  hiddenPlaceholder = false;
  searchValueExists = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  searchInputValue(searchValue: string) {
    this.searchValueExists = searchValue !== '';
  }

  onSelectPlace(place: Place) {
    this.selectPlace.emit(place);
  }

  onFindMe() {
    this.findMe.emit(true);
  }

  @ModalClose()
  goBack() {
  }

  restInput(event: any) {
    this.hiddenRestPlaceholder = event.target.value;
  }

  cityInput(event: any) {
    this.hiddenPlaceholder = event.target.value;
  }

  onSelectRestaurant($event: boolean) {
    this.selectRestaurant.emit($event);

    this.goBack();
  }
}
