import { Component, ElementRef, HostListener, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CurrentNavigationExtra } from '../../decorators/current-navigation-extra.decorator';
import { IsDevice } from '../../decorators/is-device.decorator';
import { CountryWithChild } from '../../domains/country-with-child';
import { Restaurant } from '../../domains/restaurant';
import { LocalStorage } from '../../local-storage/local-storage';
import { DeviceType } from '../../models/enums/device-type';
import { LocalStorageKey } from '../../models/enums/local-storage-key';
import { ModalType } from '../../models/enums/modal-type';
import { ExploreRouteParams } from '../../models/route-params/explore-route-params';
import { SearchParams } from '../../models/search-params';
import { SearchResult } from '../../models/search-result';
import { BackgroundImageService } from '../../services/background-image.service';
import { ExploreService } from '../../services/explore.service';
import { ModalService } from '../../services/modal.service';
import { NavigatorService } from '../../services/navigator.service';
import { RouterService } from '../../services/router.service';
import { WebSocketService } from '../../services/web-socket.service';
import { StateListener } from '../../websocket/listeners/state-listener';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styles: []
})
export class ExploreComponent implements OnInit, OnDestroy {
  @CurrentNavigationExtra('clearSearchParams') clearSearchParams: boolean;

  @ViewChild('emailModalRef') emailModalRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  private readonly showEmailQueryParam = 'show-email';

  exploreRouteParams: ExploreRouteParams;
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);
  country: CountryWithChild;
  searchParams: SearchParams;
  websocketStateListener: StateListener;
  searchedHash = '';
  isSearching = false;
  exploreRouteParamsSubscription: Subscription;
  searchResult = new SearchResult();
  currentRestaurant: Restaurant;
  isRouteReady = false;

  constructor(
    private logger: NGXLogger,
    private exploreService: ExploreService,
    private backgroundImageService: BackgroundImageService,
    private webSocketService: WebSocketService,
    private routerService: RouterService,
    private navigatorService: NavigatorService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    if (this.clearSearchParams) {
      this.searchParamsLocalStorage.removeItem();
    }

    this.setScrollbarWidth();

    this.exploreRouteParamsSubscription = this.routerService.exploreRouteParamsSubject
      .subscribe(exploreRouteParams => {
        if (exploreRouteParams) {
          this.exploreRouteParams = exploreRouteParams;

          this.activatedRoute.queryParams
            .subscribe(queryParams => {
              this.searchParams = this.searchParamsLocalStorage.getItem();

              this.isRouteReady = true;

              if (queryParams.hasOwnProperty(this.showEmailQueryParam) && queryParams[this.showEmailQueryParam]) {
                this.modalService.open(
                  this.emailModalRef, this.isMobile ? ModalType.PREVIEW_SWIPER : ModalType.LOGIN_HELPER
                );

                this.navigatorService.removeQueryParam(this.showEmailQueryParam);
              }

              this.exploreService
                .getLocations(this.searchParams)
                .subscribe(countries => {
                  this.backgroundImageService.setNewRandomPhoto();

                  // TODO Selecting country fix
                  this.country = countries.find(county => county.key === 'US');

                  this.websocketStateListener = this.webSocketService
                    .registerListener(new StateListener());

                  this.websocketStateListener.subject.subscribe(stateEdited => {
                    const state = this.country.states.find(stateI =>
                      stateI.id === stateEdited.id
                    );

                    if (state === undefined) {
                      this.country.states.push(stateEdited);
                    } else {
                      state.counties = stateEdited.counties;
                    }
                  });
                });

              if (this.searchParams.countyId) {
                this.doSearch();
              }
            });
        }
      });
  }

  ngOnDestroy() {
    this.exploreRouteParamsSubscription.unsubscribe();
  }

  doSearch() {
    if (this.searchedHash !== this.searchParams.hash) {
      this.isSearching = true;

      this.exploreService
        .search()
        .subscribe({
          next: searchResult => {
            this.searchResult = searchResult;
            this.searchResult.isReady = true;
            this.searchedHash = this.searchParams.hash;
            this.exploreService.searchResultSubject.next(this.searchResult);

            setTimeout(() => {
              this.setScrollbarWidth();
            }, 500);
          }, error: error => {
            this.logger.error('On search', error);
          }
        })
        .add(() => {
          this.isSearching = false;
        });
    }
  }

  private setScrollbarWidth(): void {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setScrollbarWidth();
  }

  onEditRestaurantsMenu($event: Restaurant) {
    this.routerService.setRestaurantHostnameAndLocationId($event.hostname, $event.locationIndex);

    this.currentRestaurant = $event;
  }

  onSaveChanges() {
    const restaurant = this.currentRestaurant;

    this.routerService.setRestaurantHostnameAndLocationId(null, null);
    this.currentRestaurant = null;

    this.navigatorService.goToRestaurant(restaurant.hostname, restaurant.locationIndex);
  }
}
