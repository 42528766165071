<a
  appHrefNone
  class="icon-main-circle-dark-container"
>
  <app-picker
    *ngIf="isLoggedIn && isSubscribed"
    [classes]="'icon main-circle-dark'"
    [picker]="picker"
    (selectedValue)="onRecentRestaurantChanged($event)"
  ></app-picker>

  <span
    *ngIf="!explore"
    class="icon chains-circle"
    (click)="openMembershipModal()"
  ></span>

  <div *ngIf="explore">
    <app-picker
      [classes]="'icon rest-selector'"
      [picker]="searchResult.restaurantsPicker"
      [title]="searchParams.locationName"
      (selectedValue)="onRestaurantsPickerSelected($event)"
    ></app-picker>
  </div>
</a>
