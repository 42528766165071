<div *appIfIsMobile class="top-input-modal safe-area-top bg-white">
  <div class="row border-black mx-0">
    <div class="col-10 px-0">
      <i *ngIf="!hiddenRestPlaceholder" class="icon-v2 search-black-new"></i>

      <app-search-restaurant-input
        [searchParams]="searchParams"
        inputClass="top-input-modal-input search-filters-mobile-location-input"
        (input)="restInput($event)"
        (selectRestaurant)="onSelectRestaurant($event)"
      ></app-search-restaurant-input>
    </div>

    <div
      *ngIf="!searchValueExists"
      class="close-button px-1-25rem safe-area-top col-2 d-flex justify-content-end"
      (click)="goBack()"
    >
      <i class="icon-v2 arrow-back scale-0-8"> </i>
    </div>
  </div>

  <div class="row mx-0">
    <div>
      <i *ngIf="!hiddenPlaceholder" class="icon-v2 search-black-new"></i>

      <app-search-city-input
        inputClass="top-input-modal-input search-filters-mobile-location-input"
        (inputValue)="searchInputValue($event)"
        (selectPlace)="onSelectPlace($event)"
        (input)="cityInput($event)"
      ></app-search-city-input>
    </div>

    <div
      *ngIf="!searchValueExists"
      class="search-city-find-me px-1-25rem safe-area-top"
    >
      <a appHrefNone class="text-black" (click)="onFindMe()">Find me</a>
    </div>
  </div>
</div>
