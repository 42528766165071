import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Food } from '../../../domains/food';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { FavoriteStatus } from '../../../models/enums/favorite-status';
import { Picker } from '../../../models/picker';
import { FavoritesService } from '../../../services/favorites.service';

@Component({
  selector: 'app-heart',
  templateUrl: './heart.component.html',
  styleUrls: ['./heart.component.scss']
})
export class HeartComponent implements OnInit {
  @Input() imageHovered: boolean;
  @Input() food: Food;
  @Input() favoriteModal = false;
  @Input() defaultStatus: FavoriteStatus;

  @Output() favoriteStatusChanged = new EventEmitter<FavoriteStatus>();

  @ViewChild('mmmmButtonRef') mmmmButtonRef: ElementRef;

  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  foodFavoritePicker = new Picker();

  constructor(
    private favoritesService: FavoritesService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
    if (this.food) {
      this.food.favoriteStatus.statuses.forEach(status => {
        this.foodFavoritePicker.addDefaultOption(status.value, status.title);
      });
    }

    if (!this.defaultStatus && this.currentUser) {
      this.favoritesService
        .getFavoriteStatus(this.food.id)
        .subscribe({
          next: favoriteStatus => {
            this.food.favoriteStatus.setStatus(favoriteStatus ? favoriteStatus : FavoriteStatus.NOT_EATEN);

            this.food.favoriteStatus.statuses.forEach(status => {
              status.isSelected = (status.value === favoriteStatus);
            });

            this.favoriteStatusChanged.emit(favoriteStatus);
          }, error: error => {
            this.logger.error('On getting favorite by id', error);
          }
        });
    } else {
      this.food.favoriteStatus.setStatus(this.defaultStatus);

      this.food.favoriteStatus.statuses.forEach(status => {
        status.isSelected = (status.value === this.defaultStatus);
      });
    }
  }

  toggleHeartDesktop(favoriteStatus: FavoriteStatus, event) {
    event.stopPropagation();

    this.toggleHeart(favoriteStatus);

    this.food.favoriteStatus.statuses.forEach(status => {
      status.isSelected = (status.value === favoriteStatus);
    });
  }

  @Auth()
  toggleHeart(favoriteStatus: FavoriteStatus) {
    this.mmmmButtonRef.nativeElement.classList.add('pulsate-fwd');

    this.food.favoriteStatus.setStatus(favoriteStatus);

    setTimeout(() => {
      this.mmmmButtonRef.nativeElement.classList.remove('pulsate-fwd');
    }, 500);

    this.favoritesService
      .setFavoriteStatus(this.food.id, favoriteStatus)
      .subscribe({
        next: favoriteStatusNew => {
          this.food.favoriteStatus.setStatus(favoriteStatusNew ? favoriteStatusNew : FavoriteStatus.NOT_EATEN);

          this.favoriteStatusChanged.emit(favoriteStatus);
        }, error: error => {
          this.logger.error('On toggling favorite', error);
        }
      });
  }
}
