import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { DeviceType } from '../../../../models/enums/device-type';
import { RestaurantLocation } from '../../../../models/restaurant-location';
import { SearchParams } from '../../../../models/search-params';
import { NavigatorService } from '../../../../services/navigator.service';
import { RestaurantService } from '../../../../services/restaurant.service';

@Component({
  selector: 'app-search-restaurant-input',
  templateUrl: './search-restaurant-input.component.html',
  styleUrls: [
    'search-restaurant-input.component.scss',
    '../search-location-input.scss'
  ]
})
export class SearchRestaurantInputComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() searchParams: SearchParams;
  @Input() inputClass: string;
  @Input() inputHover: boolean;

  @Output() selectRestaurant = new EventEmitter<boolean>();
  @Output() inputClick = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  focus$ = new Subject<string>();
  defaultItems: RestaurantLocation[] = [];

  constructor(
    private renderer: Renderer2,
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService,
  ) {
  }

  ngOnInit(): void {
    if (this.isDesktop) {
      const findMe = new RestaurantLocation();
      findMe.disabled = true;
      this.defaultItems.push(findMe);
    }
  }

  ngAfterViewInit() {
    this.adjustDropdownWidth();
  }

  ngAfterViewChecked() {
    this.adjustDropdownWidth();
  }

  adjustDropdownWidth() {
    if(this.isDesktop) {
      const inputElement = document.getElementById('restaurant-input');
      const dropdownElement = document.querySelector('ngb-typeahead-window.search-location-popup-desktop-restaurant');

      if (dropdownElement) {
        this.renderer.setStyle(dropdownElement, 'width', `${inputElement.offsetWidth - 0.5}px`);
      }
    }
  }

  onFocusInput() {
    this.focus$.next('');
  }

  onHoverInput() {
    this.focus$.next('');
  }

  onInputClick(event: any) {
    const inputValue = event.target.value;
    this.inputClick.emit(inputValue);

    if (inputValue === '') {
      this.focus$.next('');
    }
  }

  searchRest: any = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      debounceTime(100),
      switchMap(term => {
        if (term === '' || term.length < 3) {
          return of(this.defaultItems);
        } else {
          return this.restaurantService.search(this.searchParams.locationType, this.searchParams.locationId, term).pipe(
            switchMap((res: any) => {
              return of(res);
            })
          );
        }
      })
    );
  }

  formatter = (restaurantLocation: RestaurantLocation) => restaurantLocation.display;

  selectItem(placeEvent: NgbTypeaheadSelectItemEvent<RestaurantLocation>) {
    if (placeEvent.item.disabled) {
      placeEvent.preventDefault();
    }

    this.selectRestaurant.emit(true);

    this.navigatorService.goToUrlWithReload(
      `${placeEvent.item.locationPath}/restaurants`,
      {restaurantId: placeEvent.item.restaurantId, openPreviewModal: true}
    );
  }
}
