<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <app-navbar-bottom-icon-feed></app-navbar-bottom-icon-feed>

    <div class="flex-content-vc-hc">
      <app-navbar-bottom-icon-recent
        [searchParams]="searchParams"
        [searchResult]="searchResult"
        [explore]="true"
        (restaurantClicked)="restaurantClicked($event)"
      ></app-navbar-bottom-icon-recent>
    </div>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch (menuClick)="onMainMenuClick()">
    <i
      [ngClass]="{'profile-mmmm scale-1_2': rotateMainLogo, 'main-grey': !rotateMainLogo}"
      class="icon"
    ></i>
  </app-navbar-bottom-semicircle-notch>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <span
      *ngIf="isLoggedIn"
      class="icon call"
      (click)="onFavoritesClick()"
    ></span>

    <span
      *ngIf="!isLoggedIn"
      class="icon-v2 play mb-1"
      (click)="onVideoClick()"
    ></span>

    <app-navbar-bottom-icon-user></app-navbar-bottom-icon-user>
  </nav>
</div>
